<template>
  <div class="sidebar">
    <div>
      <div
        v-if="getShapeVisible"
        class="shape"
        @click="
          closePopup(
            {
              event: 'cEvent',
              category: 'Pop Up',
              action: 'Close',
              label: getPopups.popupName,
              value: 0,
              isNonInteraction: false,
            },
            { type: 'BGClose' },
          )
        "
      />
      <div
        v-if="customerPoints === 0 || customerPoints"
        :style="
          ['KACC', 'LAMP'].includes(constants.userType) || !isPartner
            ? 'visibility:hidden;'
            : 'visibility:visible;'
        "
        class="points-container"
      >
        <VueText sizeLevel="6" weightLevel="3">Biriken Puanlarım</VueText>
        <div class="points-wrapper" @click="goWallets">
          <VueText class="points" sizeLevel="5" weightLevel="3">{{
            customerPoints | currencyFormat
          }}</VueText>
          <img
            src="@/assets/user/point-coins.png"
            alt="user-points"
            :class="customerPoints > 0 ? 'rotating-glow' : null"
          />
        </div>
      </div>
      <BrandCompetitionSummary v-if="!this.isImageRecognitionUser && !isLampUser" />
      <ImsakiyeCard v-if="!this.isImageRecognitionUser" />
      <MarathonCard v-if="!this.isImageRecognitionUser" />
      <RosetteCard v-if="!this.isImageRecognitionUser" />
      <MarathonPopup @closePopup="closePopup" />
      <DoWinCard :style="getDoWinCardStyle" class="dowin-card" v-if="!this.isImageRecognitionUser">
        <DoWinPopup @closePopup="closePopup" />
      </DoWinCard>
      <BrandButton
        v-if="isOtpAvailable"
        class="otp-btn"
        as="router-link"
        :target="'/secure/tutun-akademi'"
        @click.native="tutunAkademiDataLayer"
      />
      <ButtonVirtualVisit
        v-if="chefShopAvailable && !isImageRecognitionUser && !isLampUser"
        id="chefs_shop_button"
        :style="getVirtualVisitButtonStyle"
      ></ButtonVirtualVisit>
      <ButtonChatbot v-if="!this.isImageRecognitionUser"></ButtonChatbot>
      <ButtonPreOrder v-if="customerDetail && customerDetail.isEOrderCustomer"></ButtonPreOrder>
      <VirtualVisitPopup @closePopup="closePopup" />
    </div>
    <UndeliveredAtomCallPopup @closePopup="closePopup" />
    <AtomSurveyPopup @closePopup="closePopup" />
    <RosettePopup @closePopup="closePopup" />
    <div class="ad-wrapper-small"><div id="div-gpt-ad-1587046855075-0"></div></div>
  </div>
</template>
<script>
import VueText from 'VueText';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import {
  COMPONENT_SIZES,
  COMPONENT_SHAPES,
  ICON_VARIABLES,
} from '@/constants/component.constants.js';
import ImsakiyeCard from '../SideBar/ImsakiyeCard.vue';
import DoWinCard from '@/components/brand/SideBar/DoWinCard';
import MarathonCard from '@/components/brand/SideBar/MarathonCard';
import RosetteCard from '@/components/brand/SideBar/RosetteCard';
import ButtonChatbot from '@/components/brand/SideBar/ButtonChatbot';
import ButtonVirtualVisit from '@/components/brand/SideBar/ButtonVirtualVisit';
import ButtonPreOrder from '@/components/brand/SideBar/ButtonPreOrder';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import StorageProps from '@/mixins/storageProps.js';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import currencyFilter from '@/mixins/currencyFilter.js';
import StorageHelper from '@/utils/storageHelper';
import BrandCompetitionSummary from '@/components/brand/BrandCompetitionSummary/BrandCompetitionSummary.vue';
import { mapGetters } from 'vuex';
import AccountMixin from '@/utils/accountUtils.js';
import VirtualVisitPopup from '../../../views/pages/secure/popups/VirtualVisitPopup.vue';
import DoWinPopup from '../../../views/pages/secure/popups/DoWinPopup.vue';
import MarathonPopup from '../../../views/pages/secure/popups/MarathonPopup.vue';
import AtomSurveyPopup from '../../../views/pages/secure/popups/AtomSurveyPopup.vue';
import UndeliveredAtomCallPopup from '../../../views/pages/secure/popups/UndeliveredAtomCallPopup.vue';
import RosettePopup from '../../../views/pages/secure/popups/RosettePopup.vue';

export default {
  name: 'BrandSideBar',
  mixins: [StorageProps, gtmUtils, currencyFilter, AccountMixin],
  props: ['currentPopup'],
  components: {
    VueText,
    DoWinCard,
    ButtonChatbot,
    ButtonVirtualVisit,
    ButtonPreOrder,
    BrandButton,
    BrandCompetitionSummary,
    MarathonCard,
    ImsakiyeCard,
    RosetteCard,
    VirtualVisitPopup,
    DoWinPopup,
    MarathonPopup,
    AtomSurveyPopup,
    UndeliveredAtomCallPopup,
    RosettePopup,
  },
  data() {
    return {
      chefsShopURL: `${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`,
      chefShopAvailable: false,
      isOtpAvailable: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getUserModel']),
    ...mapGetters('fun', { selectedTabId: 'activeTab' }),
    ...mapGetters('app', ['getPopups']),
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        shapes: COMPONENT_SHAPES,
        size: COMPONENT_SIZES,
        icons: {
          eorder: { ...ICON_VARIABLES.eorder, color: '#ffffff' },
          chefMale: { ...ICON_VARIABLES.chefMale, color: '#79838e' },
          personRight: { ...ICON_VARIABLES.personRight, color: '#F38314' },
          personLeft: { ...ICON_VARIABLES.personLeft, color: '#BD202E' },
          virtualVisit: { ...ICON_VARIABLES.virtualVisit, color: '#d02d2c' },
        },
        userType: new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).get(),
      };
    },
    getShapeVisible() {
      return (
        !!this.getPopups.popupId &&
        this.getPopups.popupId != 6 &&
        this.$route.fullPath == '/secure/feed'
      );
    },
    getDoWinCardStyle() {
      return this.currentPopup.popupId == 4 && this.$route.fullPath == '/secure/feed'
        ? { zIndex: 15 }
        : { zIndex: 8 };
    },
    getVirtualVisitButtonStyle() {
      return this.currentPopup.popupId == 3 ? { zIndex: 15 } : { zIndex: 8 };
    },
    isImageRecognitionUser() {
      return this.getUserModel?.isImageRecognitionUser;
    },
  },
  methods: {
    tutunAkademiDataLayer() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('tutun_akademi', {
        location: 'sidebar',
      });
    },
    setPopupGAEvent(eventData) {
      this.pushDataLayerEvent('pop-up', eventData);
    },
    setPopupClickEvent(eventData, action) {
      this.pushDataLayerEvent('popup_click', {
        event: 'popup_click',
        popup_location: 'anasayfa',
        popup_cancelled: !!(action.type !== 'LinkClick'),
        popup_name: eventData.label,
        popup_platform: 'PmAktif',
      });
    },
    goToTab() {
      if (this.$route.name === 'fun') {
        this.$store.dispatch('fun/selectedTab', 225);
      } else {
        this.$router.push({ name: 'fun', params: { selectedTab: true } });
      }
    },
    goWallets() {
      if (this.$route.fullPath !== `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}`) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('cuzdanlarim', {
          location: 'sidebar',
        });
        this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}`);
      }
    },
    checkMenu() {
      let cachedMenuData = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
      if (!cachedMenuData || !cachedMenuData.menuItems) {
        Common.getMenu().then(res => {
          if (!res.data?.Data) return;
          this.cachedMenuData = res.data.Data;
          this.checkCampaigns(this.cachedMenuData);
          this.checkOtp(this.cachedMenuData);
        });
      } else {
        this.checkCampaigns(cachedMenuData);
        this.checkOtp(cachedMenuData);
      }
    },
    checkCampaigns(cachedMenuData) {
      cachedMenuData?.menuItems?.forEach(element => {
        if (
          element?.name === 'Şef Dükkanında Özetim' ||
          element?.id === 19 ||
          element?.screenCode === 18
        ) {
          this.chefShopAvailable = true;
        } else {
          element.children?.forEach(children => {
            if (
              children.name === 'Şef Dükkanında Özetim' ||
              children.id === 19 ||
              children.screenCode === 18
            ) {
              this.chefShopAvailable = true;
            }
          });
        }
      });
    },
    checkOtp(cachedMenuData) {
      cachedMenuData?.menuItems?.forEach(parent => {
        if (parent.screenCode == 32) {
          parent?.children?.findIndex(i => i.screenCode === 22) !== -1
            ? (this.isOtpAvailable = true)
            : (this.isOtpAvailable = false);
        }
      });
    },
    closePopup(eventValue, action) {
      if (eventValue) {
        this.setPopupGAEvent(eventValue);
        this.setPopupClickEvent(eventValue, action);
      }
      const popupId = this.getPopups.popupId;
      const popupDetail = this.getPopups.popupDetail;

      const request = {
        popupId,
        popupDetailObjectKey:
          popupId == 4 || popupId == 5 ? (popupId == 4 ? 'TaskId' : 'SurveyId') : null,
        popupDetailObjectValue:
          popupId == 4 || popupId == 5
            ? popupId == 4
              ? popupDetail.taskId?.toString() ?? ''
              : popupDetail.surveyId?.toString() ?? ''
            : null,
        actionType: action.type,
      };
      Common.viewPopup(request);
      this.$store.dispatch('app/setPopups', {});
      this.$emit('closePopup');
      document.body.style.overflow = 'unset';
    },
  },
  mounted() {
    //initialize google ads for homepage
    this.pushAds('small');
    this.checkMenu();
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';

.shape {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: #000;
  opacity: 0.8;
}
.sidebar {
  max-height: 100%;
  height: 50vh;
  width: $sidebar-width;
  min-width: $sidebar-width;
  background-color: $brand-white;
  padding: 0 palette-space-level(20);
  margin-top: palette-space-level(15);
  right: 0;
  & > div {
    &:nth-child(n) {
      margin-bottom: palette-space-level(20);
    }
  }
  .user-avatar {
    height: palette-space-level(80);
    max-height: palette-space-level(80);
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: palette-space-level(60);
      height: palette-space-level(60);
    }
    .user-name {
      padding-left: palette-space-level(10);
    }
  }
  .points-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .points-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: palette-space-level(35);
        height: palette-space-level(35);
      }
      .points {
        border: 1px solid palette-color-level('black', '100');
        border-radius: palette-space-level(20);
        padding: palette-space-level(6);
        margin-right: palette-space-level(6);
      }
    }
  }

  .eorder-button {
    height: 68px !important;
    margin: palette-space-level(15) 0;
  }

  .chefs-shop-button {
    margin: palette-space-level(15) 0;
    height: 68px !important;

    &:after {
      background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #d20051, #ffa300) border-box;
      border: 2px solid transparent;
    }
  }

  .do-it-together-button {
    margin-top: palette-space-level(12);
    height: 72px !important;
    background-image: url('~@/assets/bg/btn_bg.jpg');
  }
}
.otp-btn {
  margin-top: 15px;
  margin-bottom: 5px;
  height: 68px !important;
  font-size: 18px;
  background-image: url('~@/assets/otp/bg/otp_btn_bg.png') !important;
  background-size: cover;
}
.dowin-card {
  position: relative;
}
</style>
