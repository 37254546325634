export const ENVIRONMENTS = {
  QA: 'qa',
  DEV: 'dev',
  PROD: 'prod',
};

const DOMAINS = {
  AWS: '.aws.pmicloud.biz',
  PROD: '.pmaktif.com',
};

const SUBDOMAINS = {
  [ENVIRONMENTS.QA]: '.pmaktiftr',
  [ENVIRONMENTS.DEV]: '.pmaktiftr',
  [ENVIRONMENTS.PROD]: '',
};

const ENV_PREFIXES = {
  [ENVIRONMENTS.QA]: '-qa',
  [ENVIRONMENTS.DEV]: '-dev',
  [ENVIRONMENTS.PROD]: '',
};

/**
 * Get the current environment based on VUE_APP_API_BASE_URL
 */
const getCurrentEnvironment = () => {
  const apiUrl = process.env.VUE_APP_API_BASE_URL;

  if (!apiUrl) {
    console.warn('VUE_APP_API_BASE_URL is not defined, defaulting to PROD environment');
    return ENVIRONMENTS.PROD;
  }

  if (apiUrl.includes(ENVIRONMENTS.QA)) return ENVIRONMENTS.QA;
  if (apiUrl.includes(ENVIRONMENTS.DEV)) return ENVIRONMENTS.DEV;
  return ENVIRONMENTS.PROD;
};

/**
 * Build the content URL for the given environment
 */
const buildContentUrl = environment => {
  const domain = environment === ENVIRONMENTS.PROD ? DOMAINS.PROD : DOMAINS.AWS;
  return `https://content${SUBDOMAINS[environment]}${ENV_PREFIXES[environment]}${domain}`;
};

/**
 * Content URL for the current environment
 */
export const CONTENT_URL = buildContentUrl(getCurrentEnvironment());
