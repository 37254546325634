<template>
  <component
    :key="renderKey"
    :is="as"
    :class="cssClasses.modalWrapperClasses"
    v-if="mergedConfig.isModalOpened || false"
    @click="closeToModal"
  >
    <a
      v-if="mergedConfig.closeButton"
      v-on="mergedConfig.onCloseOpenQr ? { click: closeClick } : { click: closeModal }"
      :class="[cssClasses.modalCloseClasses]"
    >
      <VueIcon
        iconName="IconCross"
        iconColor="#FFF"
        :width="getIcon.cross.width"
        :height="getIcon.cross.height"
      ></VueIcon>
    </a>
    <div
      :class="cssClasses.modalContentClasses"
      :style="{ maxWidth: mergedConfig.imgSrc && '750px' }"
      id="modal-content-id"
    >
      <div :class="cssClasses.modalContentWrapperClasses">
        <div v-if="!mergedConfig.isPdfModal">
          <div v-if="mergedConfig.modalType === 'alert'" :class="cssClasses.modalIconWrapper">
            <VueIcon
              v-if="!mergedConfig.noIcon"
              :key="mergedConfig.alertType"
              :iconName="getAlertName"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
              color="#E5472D"
            />
          </div>
          <div
            v-if="
              mergedConfig.modalType === 'surveyAlert' || mergedConfig.modalType === 'uploadAlert'
            "
            :class="cssClasses.modalIconWrapper"
          >
            <VueIcon
              :key="mergedConfig.alertType"
              :iconName="getAlertName"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
            />
            <VueText sizeLevel="8" weightLevel="3" style="margin-top: 15px">{{
              mergedConfig.alertType === 'error' ? 'Üzgünüz!' : 'Tebrikler!'
            }}</VueText>
          </div>
          <div
            v-if="mergedConfig.modalType === 'loadingAlert'"
            :class="cssClasses.modalIconWrapper"
          >
            <VueIcon
              :iconName="getIcon.time.name"
              :width="getIcon.time.width"
              :height="getIcon.time.height"
            />
          </div>
          <div :class="$style.modalTitle">
            <VueText
              sizeLevel="12"
              weightLevel="5"
              v-if="mergedConfig.modalTitle"
              v-html="mergedConfig.modalTitle"
            >
            </VueText>
          </div>
          <div>
            <VueText
              as="span"
              sizeLevel="7"
              v-if="mergedConfig.modalText"
              v-html="mergedConfig.modalText"
            >
            </VueText>
            <div
              class="confirmation-division"
              v-if="mergedConfig.modalType === 'confirmation' && mergedConfig.modalConfirmationText"
            ></div>
            <VueText
              as="span"
              sizeLevel="6"
              v-if="mergedConfig.modalType === 'confirmation' && mergedConfig.modalConfirmationText"
            >
              <b>{{ mergedConfig.modalConfirmationText }}</b>
            </VueText>
            <div
              class="confirmation-division"
              v-if="mergedConfig.modalType === 'confirmation' && mergedConfig.modalConfirmationText"
            ></div>
          </div>
        </div>
        <div v-if="mergedConfig.isPdfModal" :class="cssClasses.modalPDFClasses">
          <img alt="bill" id="modal-bill-preview" :src="getModalImageSource" />
        </div>
        <div v-if="mergedConfig.imgSrc">
          <img style="max-height: 500px;" :src="mergedConfig.imgSrc" alt="raf fotoğrafı" />
        </div>
      </div>
      <div
        v-if="
          mergedConfig.modalType !== 'plain' &&
            !mergedConfig.isPdfModal &&
            mergedConfig.modalType !== 'surveyAlert'
        "
        :class="cssClasses.modalButtonWrapperClasses"
        :style="{ flexDirection: mergedConfig.isBtnReverse && 'row-reverse' }"
      >
        <BrandButton
          :disabled="mergedConfig.isBtnDisabled"
          @click="clickFirstButton"
          :size="sizes.xxLarge"
          :whiteSpaceWrap="mergedConfig.isBtnWhiteSpaceWrap"
          :class="cssClasses.btnClasses"
          >{{ mergedConfig.firstButtonText }}</BrandButton
        >
        <BrandButton
          @click="clickSecondButton"
          outlined
          :size="sizes.xxLarge"
          v-if="mergedConfig.isDoubleButton"
          :whiteSpaceWrap="mergedConfig.isBtnWhiteSpaceWrap"
          :class="cssClasses.btnClasses"
          >{{ mergedConfig.secondButtonText }}</BrandButton
        >
      </div>
      <div v-if="mergedConfig.modalType === 'surveyAlert'">
        <BrandButton @click="clickRedirect(mergedConfig.surveyType)" :size="sizes.xxLarge">{{
          mergedConfig.firstButtonText
        }}</BrandButton>
      </div>
    </div>
    <BrandButton
      as="a"
      @click.prevent="download"
      v-if="mergedConfig.isPdfModal && mergedConfig.imgData"
      :size="sizes.xxLarge"
      >{{ mergedConfig.firstButtonText }}</BrandButton
    >
  </component>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { mapGetters } from 'vuex';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { CONTENT_URL } from '@/constants/urls.constants.js';
import DownloadFile from '@/utils/downloadFile.js';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';

export default {
  name: 'BrandConfirmationModal',
  components: {
    VueIcon,
    BrandButton,
    VueText,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      renderKey: 0,
      disabled: false,
      modalConfig: {
        noIcon: false,
        closeButton: true,
        closeOnOverlayClick: true,
        modalType: 'confirmation',
        contentAlignment: FLEX_JUSTIFIES.center,
        textAlignment: FLEX_JUSTIFIES.center,
        isDoubleButton: false,
        alertType: undefined,
        firstButtonText: 'EVET',
        secondButtonText: 'İPTAL',
        modalConfirmationText: 'Onaylıyor musunuz?',
        categoryBgId: 0,
        isLearningComplated: false,
      },
    };
  },
  watch: {
    mergedConfig: {
      handler: function(newVal) {
        this.renderKey += 1;
        document.body.style.overflow = newVal.isModalOpened ? 'hidden' : 'unset';
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    mergedConfig() {
      return { ...this.modalConfig, ...this.getModalConfig };
    },
    cssClasses() {
      const modalWrapperClasses = [this.$style[`modal-wrapper`]];
      const modalContentClasses = [this.$style[`modal-content`]];
      const btnClasses = [this.$style[``]];
      const modalContentWrapperClasses = [
        this.$style[`modal-content-wrapper`],
        this.$style[`modal-content-${this.mergedConfig.contentAlignment}`],
      ];
      const modalIconWrapper = [this.$style[`modal-content-icon`]];
      const modalCloseClasses = [this.$style[`modal-close`]];
      const modalButtonWrapperClasses = [this.$style[`modal-button-wrapper`]];
      const modalPDFClasses = [this.$style[`modal-content-pdf`]];
      const secondaryButtonContainer = [this.$style['secondary-button-container']];
      if (this.mergedConfig.isPdfModal) {
        modalContentWrapperClasses.push(this.$style[`modal-content-wrapper-nospace`]);
      }

      if (this.mergedConfig.textAlignment) {
        modalContentWrapperClasses.push(
          this.$style[`modal-content-text-${this.mergedConfig.textAlignment}`],
        );
      }
      if (this.mergedConfig.isDoubleButton) {
        modalButtonWrapperClasses.push(this.$style[`secondary-button-container`]);
      }
      if (this.mergedConfig.modalType === 'success') {
        modalContentClasses.push(this.$style[`success-content`]);
        modalButtonWrapperClasses.push(this.$style[`success-button-container`]);
        btnClasses.push(this.$style[`success-btn`]);
        modalContentWrapperClasses.push(this.$style[`modal-content-wrapper-bg-img`]);
        modalContentWrapperClasses.push(
          this.$style[`modal-content-wrapper-bg-img-${this.mergedConfig.categoryBgId}`],
        );
      }
      if (this.mergedConfig.isLearningComplated) {
        modalContentClasses.push(this.$style['complated']);
      }

      return {
        modalWrapperClasses,
        modalCloseClasses,
        modalContentClasses,
        modalIconWrapper,
        modalButtonWrapperClasses,
        modalContentWrapperClasses,
        modalPDFClasses,
        secondaryButtonContainer,
        btnClasses,
      };
    },
    getIcon() {
      const { apply, cross, warn, time } = ICON_VARIABLES;
      return { cross, warn, apply, time };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    ...mapGetters('app', ['getModalConfig']),
    isRegularLink() {
      return this.as === 'a';
    },
    isDisabled() {
      return this.disabled;
    },
    getAlertName() {
      switch (this.mergedConfig.alertType) {
        case 'error':
          return 'IconError';
        case 'warn':
          return 'IconWarn';
        default:
          return 'IconApply';
      }
    },
    getModalImageSource() {
      const convertedImg = `data:image/jpeg;base64,${this.mergedConfig.imgData}`;

      return convertedImg;
    },
  },
  methods: {
    clickRedirect(surveyType) {
      let route = `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`;
      if (surveyType === 2) {
        this.$router.push(route);
      } else {
        this.closeModal();
      }
    },
    download() {
      new DownloadFile('fatura', this.getModalImageSource);
    },
    closeModal() {
      if (this.mergedConfig.isLearningComplated) {
        this.$router.push({ name: 'Otp' });
      }
      this.$store.dispatch('app/setModalConfig', {});
    },
    closeClick() {
      this.$store.dispatch('app/setCloseClick', true);
      this.$store.dispatch('app/setModalConfig', {});
      setTimeout(() => {
        this.$store.dispatch('app/setCloseClick', false);
      }, 0);
    },
    closeToModal(e) {
      if ('closeOnOverlayClick' in this.mergedConfig && !this.mergedConfig.closeOnOverlayClick) {
        return;
      }
      if (e.target.classList.contains(this.cssClasses.modalWrapperClasses)) {
        if (this.mergedConfig.isLearningComplated) {
          this.$router.push({ name: 'Otp' });
        }
        this.$store.dispatch('app/setModalConfig', {});
      }
    },
    clickFirstButton(e) {
      if (this.isRegularLink && this.isDisabled) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (!this.isDisabled) {
        this.$emit('clickFirstButton', e);
      }

      if (this.mergedConfig.firstButtonFn) {
        this.mergedConfig.firstButtonFn.apply();
      }

      if (!this.mergedConfig.isDoubleButton) {
        this.closeModal();
      }
    },
    clickSecondButton(e) {
      if (this.isRegularLink && this.isDisabled) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (!this.isDisabled) {
        this.$emit('clickSecondButton', e);
      }

      if (this.getModalConfig.secondaryButtonFn) {
        this.getModalConfig.secondaryButtonFn.apply();
      }

      this.closeModal();
    },
  },
  updated() {
    const contentId = document.getElementById('modal-content-id');

    if (this.mergedConfig.isLearningComplated) {
      contentId.style.backgroundImage = `url(${CONTENT_URL}/Files/Slider/congrats_Web.png)`;
    }
  },
};
</script>
<style module lang="scss">
.modal {
  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: palette-space-level('20');
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.7);
  }

  &-content {
    max-width: 500px;
    overflow: hidden; // Alt radius gorunmemesi durumu icin
    margin: auto;
    z-index: 1;
    background-color: palette-color-level('white', '100');
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    word-break: break-word;
    &.success-content {
      background-color: transparent;
      max-width: 490px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      &.complated {
        width: 100%;
        max-width: 1000px;
        height: 700px;
        background-size: cover;
        margin-top: auto;
        .modal-content-wrapper-bg-img,
        .success-button-container {
          display: none;
        }
      }
    }

    &-text {
      &-center {
        text-align: center;
      }
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      min-width: 400px;
      padding: palette-space-level('45');

      &-nospace {
        padding: 0 !important;
      }
    }
    @each $variation, $alignment in $palette-flex-alignments {
      &-#{$alignment} {
        align-items: $alignment;
      }
    }
    &-icon {
      margin-bottom: palette-space-level('30');
      svg {
        width: 60px;
        height: 60px;
      }
    }

    &-pdf {
      canvas {
        max-width: 100%;
      }
    }
  }
  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &-button-wrapper {
    display: flex;
  }
}
.modalTitle {
  margin-bottom: palette-space-level('10');
}
.confirmation-division {
  padding-bottom: palette-space-level('20');
}
.secondary-button-container {
  button:first-child {
    border-radius: 0;
    border-bottom-left-radius: palette-radius-level(2);
  }
  button:last-child {
    padding: palette-space-level('16');
    margin: palette-space-level('2');
    border-radius: 0;
    border-bottom-right-radius: palette-radius-level(2);
  }
}
.modal-content-wrapper-bg-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
  width: 490px;
  &-231 {
    background-image: url('~@/assets/otp/tebrikler-231.png');
  }
  &-232 {
    background-image: url('~@/assets/otp/tebrikler-232.png');
  }
  &-233 {
    background-image: url('~@/assets/otp/tebrikler-233.png');
  }
}
.success-button-container {
  margin-top: 20px;
  background-color: transparent;
}
.success-btn {
  border-radius: 4px !important;
  padding: 10px 22px;
  min-height: 69px;
  &:after {
    bottom: 0;
    right: 0;
  }
  span {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.09;
  }
  &:last-child {
    margin-left: 5px;
    background-color: transparent;
    span {
      color: #fff !important;
    }
  }
  &:first-child {
    margin-right: 5px;
    background-color: #fff !important;
    background-image: none;
    span {
      color: #e5472d !important;
    }
  }
}
</style>
